import * as React from "react"
import Layout from '../components/Layout'
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { useInView } from 'react-intersection-observer';


import TelegramSvg from '../assets/svg/telegram.svg'
import TwitterSvg from '../assets/svg/twitter.svg'
import DiscordSvg from '../assets/svg/discord.svg'
import GithubSvg from '../assets/svg/github.svg'
import MediumSvg from '../assets/svg/medium.svg'
import YoutubeSvg from '../assets/svg/youtube.svg'
import StackoverflowSvg from '../assets/svg/stackoverflow.svg'
import EmailSvg from '../assets/svg/mail.svg'

import placeHolder from '../assets/images/placeholder.jpg'
import communtiySvg from "../assets/illustratlion/community.svg"

import andrewImg from '../assets/images/andrew-headshot.jpeg'
import twitterImg from '../assets/images/twitter.jpeg'

import './community.scss'
import { pageLink, ioOption, fadeIn } from "../utils"

// data
// const communtiySvg = "../assets/illustratlion/community.svg"

// img source in string
const communityImg = "../assets/illustratlion/community.png"

const socialMedias = [
  // { icon: <TelegramSvg />, channel: "Telegram" },
  { icon: <TwitterSvg />, channel: "Twitter", link: pageLink.twitter },
  { icon: <DiscordSvg />, channel: "Discord", link: pageLink.discord },
  // { icon: <GithubSvg />, channel: "Github", link: pageLink.github },
  // { icon: <MediumSvg />, channel: "Medium", link: pageLink.medium },
  { icon: <YoutubeSvg />, channel: "Youtube", link: pageLink.youtube},
  // { icon: <StackoverflowSvg />, channel: "Stack Overflow", link: pageLink.stackOverflow },
  { icon: <EmailSvg />, channel: "Email", link: pageLink.email },
]

const news = [
  { image: andrewImg, link: pageLink.tedx, text: "Our founder and CEO, Andrew Kwan spoke at TEDx, sharing his vision on the importance of ensuring Web3 and Metaverse serve all users, remain open and are used for good."},
  { image: twitterImg, link: pageLink.twitter, text: "We regularly share demos, exclusive news and updates about our token launch on Twitter. Connect with us there for early access and special offers."}
]

// Components
const SocialBox = ({icon, text, link}) => {
  const [ refSocialBox, inViewSocialBox, entrySocialBox ] = useInView(ioOption);

  return(
    <a ref={refSocialBox} className={`box ${text.toLowerCase().replace(' ', '-')} ${fadeIn(inViewSocialBox)}`} href={ link } target="_blank" rel="noreferrer" aria-label={text}>
      { icon }
      <div className="box__text">{ text }</div>
    </a>
  )
}

const NewsBox = ({ photo, text, link}) => {
  const [ refNewsBoxContent, inViewNewsBoxContent, entryNewsBoxContent ] = useInView(ioOption);
  const [ refNewsBoxImg, inViewNewsBoxImg, entryNewsBoxImg ] = useInView(ioOption);

  return (
    <div className="box">
      <img ref={refNewsBoxImg} className={`${fadeIn(inViewNewsBoxImg)}`} src={ photo } alt="" />
      <p ref={refNewsBoxContent} className={`box__caption ${fadeIn(inViewNewsBoxContent)}`}>{ text }</p>
      <a className={`box__button button button-100 ${fadeIn(inViewNewsBoxContent)}`} href={ link } target="_blank" rel="noreferrer" aria-label={text}>Read More</a>
    </div>
  )
}

const CommunityPage = () => {
  const [ refHeroImg, inViewHeroImg, entryHeroImg ] = useInView(ioOption);
  const [ refHeroContent, inViewHeroContent, entryHeroContent ] = useInView(ioOption);
  const [ refConnectContent, inViewConnectContent, entryConnectContent ] = useInView(ioOption);
  const [ refNewsContent, inViewNewsContent, entryNewsContent ] = useInView(ioOption);




  return (
    <Layout
      title="Include | Community"
      pageClassName="page-community"
    > 

      {/* HERO */}
      <section className="page-community__section-hero">
        <div className="page-community__section-hero__container">
          <div 
            ref={refHeroContent} 
            className={`page-community__section-hero__container__content ${fadeIn(inViewHeroContent)}`}
          >
            <h1 className="page-title">Include Community</h1>
            <p className="page-title-tag">Our community channels are a hub for creators of any (and all) experience levels, developers, token holders and supporters to stay connected and informed.</p>
          </div>
          <div className={`img-container--desktop ${fadeIn(inViewHeroImg)}`} ref={refHeroImg} >
            <StaticImage 
              className={`page-community__section-hero__container__img section-img `}
              src={ communityImg }
              objectFit="contain"
              layout="fullWidth"
              alt="" 
            />
          </div>

          {/* <img
            ref={refHeroImg} 
            className={`page-community__section-hero__container__img section-img ${fadeIn(inViewHeroImg)}`}
            src={ communtiySvg }
            alt="" 
          /> */}
        </div>
      </section>
      {/* CONNECT */}
      <section className="page-community__section-connect">
        <div className="page-community__section-connect__container">
          <h2 ref={refConnectContent} className={`title ${fadeIn(inViewConnectContent)}`}>Connect with Include</h2>
          <p className={`title-tag ${fadeIn(inViewConnectContent)}`}>We post to these channels regularly and will continue to diversify our community building efforts as we grow.</p>
          <div className="social-boxes">
            { socialMedias.map(media => 
              <SocialBox 
                key={ media.channel } 
                icon= { media.icon } 
                text={ media.channel }
                link={ media.link }
              />
            )}
          </div>
        </div>
      </section>

      {/* NEWS */}
      <section className="page-community__section-news">
        <div className="page-community__section-news__container">
          <h2 ref={refNewsContent} className={`title ${fadeIn(inViewNewsContent)}`}>News</h2>
          <p className={`title-tag  ${fadeIn(inViewNewsContent)}`}>Hear from our CEO, industry partners and broader network. You’ll also find all of the latest media coverage of Include.</p>
          <div className="news-boxes">
            { news.map(singleNews => 
              <NewsBox 
                key={singleNews.text}
                photo={ singleNews.image } 
                text={ singleNews.text } 
                link={ singleNews.link } 
              />
            )}
          </div>
        </div>
      </section>
      <div className="page-community__space-background"></div>

    </Layout>
  )
}

export default CommunityPage